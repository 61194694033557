import { Group, Vector3 } from "three";
import { gui } from "./GUI";
import PathLine from "./lib/PathLine";
import PathLineBase from "./lib/PathLineBase";
import { clipBoardCopy, pointToPosition, positionToPoint } from "./utils";

export default class SceneBase {
	id: number = -1;
	group: Group;
	name: string = "";
	sceneSvgParts: Array<PathLineBase>;
	scenePath: PathLineBase;
	connectPath: PathLineBase;
	cameraPath: PathLineBase;
	cameraPathPosition: Array<Vector3> = [];
	progress: number = 0;
	progressOld: number = -1;
	passCheck: any = { forward: false, backward: false };
	isIn: boolean = false;
	isOut: boolean = false;
	inOutPoint = 0.9;
	time: number = 0;
	dir: number = 1;
	constructor(id = -1, sceneSvgParts: Array<PathLineBase>, scenePath: PathLineBase) {
		this.id = id;
		this.sceneSvgParts = sceneSvgParts;
		this.scenePath = scenePath;

		this.sceneSvgParts.forEach((pathLine) => {
			if (id != 0) pathLine.update(0);
		});
		// console.log(`🚀 ~ this.sceneSvgParts`, this.sceneSvgParts);
	}

	update(progress, mouse) {
		this.progress = progress;

		if (this.progress != this.progressOld) {
			this.scenePath.update(this.progress);
			if (this.progress >= this.inOutPoint) {
				if (!this.isIn) {
					// this.in();
				}
				this.isIn = true;
			}

			if (this.progress < this.inOutPoint) {
				if (this.isIn) {
				}
				this.isIn = false;
			}

			let p = 0;
			if (this.progress > 0.4 && this.progress <= 0.9) {
				p = (this.progress - 0.4) / 0.5;
			}
			if (this.progress > 0.9) {
				p = 1;
			}
			this.sceneSvgParts.forEach((pathLine, i) => {
				pathLine.update(p);
			});
		}

		// this.updatePathAnimation();
		this.animation();
		this.progressOld = this.progress;
	}

	animation(mouse = { rx: 0, ry: 0 }) {}

	updatePathAnimation() {
		let sp = 0.04;
		this.time += sp;
		this.sceneSvgParts.forEach((pathLine, i) => {
			let id = 1;
			let power = 0;
			for (let j = 0; j < pathLine.points.length; j += 3) {
				power = this.time + (id % 2 == 0 ? 1 : -1); //(((this.time * j) / 3) % 3) * 1;
				power += Math.cos(j * this.time);
				pathLine.points[j + 0] =
					pathLine.pointsOrigin[j + 0] + Math.cos(this.time + j / 200 + i * 0.1) * 1.1;
				pathLine.points[j + 1] =
					pathLine.pointsOrigin[j + 1] + Math.sin(this.time + j / 200 + i * 0.1) * 1.1;

				if (j % 3 == 0) id++;
			}
			pathLine.updateLine(pathLine.points);
		});
	}

	updateScenePath() {
		let id = 1;
		let power = 0;
		let origin = null;
		let startPower = 0;
		let endPower = 0;
		for (let i = 0; i < this.scenePath.points.length - 3; i += 3) {
			power = this.time + (id % 2 == 0 ? 1 : -1); //(((this.time * j) / 3) % 3) * 1;
			power += Math.cos(i * this.time);
			origin = this.scenePath.pointsOrigin;

			startPower = i / 30;
			endPower = 1 - (i - this.scenePath.points.length - 30) / 30;

			startPower = Math.max(0, Math.min(1, startPower));
			endPower = Math.max(0, Math.min(1, endPower));

			this.scenePath.points[i + 0] = origin[i + 0] + Math.cos(this.time + i / 100) * 2;
			this.scenePath.points[i + 1] = origin[i + 1] + Math.sin(this.time + i / 100) * 2;
			if (i % 3 == 0) id++;
		}
		this.scenePath.updateLine(this.scenePath.points);
	}

	in() {
		this.sceneSvgParts.forEach((pathLine, i) => {
			pathLine.drawTo(1, 1.8, i * 0.05);
		});
	}
	out() {
		this.sceneSvgParts.forEach((pathLine) => {
			pathLine.drawTo(0, 1);
		});
	}
	setGui() {
		let g = gui.addFolder(this.name == "" ? "scene" + this.id : this.name);
		g.close();
		// g.add(this, "in");
		// g.add(this, "out");

		g.add(this.group, "visible");

		g.add(
			{
				Connectionポイント位置Copy: () => {
					let positions = this.connectPath.getPositions();
					positions.shift();
					positions.pop();
					clipBoardCopy(JSON.stringify(positionToPoint(positions)));
				},
			},
			"Connectionポイント位置Copy"
		);

		g.add(
			{
				cameraポイント位置Copy: () => {
					let positions = this.cameraPathPosition.concat([]);
					positions.shift();
					positions.pop();

					clipBoardCopy(JSON.stringify(positionToPoint(positions)));
				},
			},
			"cameraポイント位置Copy"
		);

		g.add(
			{
				sceneポジション位置Copy: () => {
					clipBoardCopy(JSON.stringify(this.group.position));
				},
			},
			"sceneポジション位置Copy"
		);
		// g = g.addFolder("connectLine");
		// this.connectPath.setGui(g);
	}
}
