import {
    BackSide,
    CameraHelper,
    Group,
    Mesh,
    Object3D,
    TubeGeometry,
    Vector2,
    Vector3,
} from "three";
import BaseWebGL from "./BaseWebGL";
import config from "./config";
import { SVGLoader } from "./lib/SVGLoader";
import GetSvgPathPoint from "./lib/GetSvgPathPoint";
import { gui, gui_vec3 } from "./GUI";
import SkeinLine from "./SkeinLine";
import LineBase from "./lib/PathLineBase";
import { OrbitControls } from "./lib/OrbitControls";
import { DragControls } from "./lib/DragControls";
import {
    changeDecimal,
    makeCurvePoint,
    makeSimpleBox,
    pointToPosition,
    positionToPoint,
} from "./utils";
import { MeshBasicMaterial } from "three";
import PathLineBase from "./lib/PathLineBase";
import SceneBase from "./SceneBase";
import Scene1 from "./Scene1";
import CircleMesh from "./CircleMesh";
import gsap from "gsap";
import CaptureRender from "./CaptureRender";

export default class TeradaStory extends BaseWebGL {
    // subScene: Scene;

    scenes: Array<SceneBase> = [];
    sceneGroups: Array<Group> = [];
    sceneSvgPathLines: Array<any> = [];
    scenePathLines: Array<PathLineBase> = [];
    scenePositionId: Array<number> = [];
    scenePositionRatio: Array<number> = [];

    cover: CircleMesh;
    coverLine: PathLineBase;
    coverLinePositions: Array<number> = [];
    coverLineRotation: number = 0;
    coverLineRotationSpeed: number = 0.015;
    coverSize: number = 557;
    coverLineLength: number = 600;

    addCameraZoom: number = 0;
    cameraZoomOffset: number = 1000;

    drawSvgToSceneList: Array<any> = [];
    subSceneTime: number = 0;

    boxGroup: Group;
    guideGroup: Group;

    skeinLine: SkeinLine;
    connectionPaths: Array<PathLineBase> = [];

    container: Object3D = new Object3D();

    cameraPath: PathLineBase;
    cameraPaths: Array<PathLineBase> = [];
    cameraPathPositions: Array<any> = [];
    cameraPathTubes: Array<Mesh> = [];
    cameraHelper: CameraHelper;

    camPosition: Vector3 = new Vector3();
    camBinormal: Vector3 = new Vector3();
    camNormal: Vector3 = new Vector3();
    camLookAt: Vector3 = new Vector3();
    camDirection: Vector3 = new Vector3();

    cameraPathTubeOption: any = {
        tubularSegments: 1000,
        tubuRadius: 30,
        radiusSegments: 10,
        lookAhead: false,
        stablization: 500,
        offset: 1,
        scale: 1,
    };

    cameraPathTube: Mesh;

    notFountPathGroup: Group;
    notFoundPath: PathLineBase;
    notFoundPaths: Array<PathLineBase> = [];

    camAcc: Vector2 = new Vector2(0, 0);

    orbitCtrl: OrbitControls;
    dragCtrl: DragControls;

    dragObjects: any = [];

    progress: any = { smooth: 0, crt: 0, old: -1 };
    progress2: any = { smooth: 0, crt: 0, old: -1 };
    showGuidePath = false;
    showTubes = false;
    isLoaded = false;
    isReady = false;
    isEditMode = false;

    operationMode = false;

    lookAt: Vector3 = new Vector3();

    statusEl;
    statusText: string = "";

    constructor(containerEl, operationMode: boolean = false) {
        super(containerEl);
        this.operationMode = operationMode;
        if (this.operationMode) {
            this.statusEl = document.createElement("div");
            this.statusEl.classList.add("status");
            this.statusEl.style.position = "fixed";
            this.statusEl.style.left = "0px";
            this.statusEl.style.padding = "1em";
            this.statusEl.style.fontSize = "12px";
            this.statusEl.style.top = "0px";
            this.statusEl.style.backgroundColor = "rgba(255,255,255,0.9)";
            this.statusEl.style.pointsEvent = "none";
            this.statusEl.style.zIndex = "100";
            this.statusEl.style.display = "block";
            document.body.append(this.statusEl);
        }

        this.cameraHelper = new CameraHelper(this.camera);
        this.cameraHelper.visible = false;
        this.container.add(this.cameraHelper);
        this.camera.position.z = config.camera_position_z;
        this.editCamera.position.z = config.camera_position_z;

        this.addResizeList("terada", (e) => {
            this.teradaResize(e);
        });
    }

    async setup() {
        this.guideGroup = new Group();
        this.container.add(this.guideGroup);
        this.scene.add(this.container);

        this.cover = new CircleMesh("frontWall", {
            colors: 0xf7f4ec,
            baseColor: 0xf7f4ec,
            maskRadiusIn: 0,
            maskRadiusOut: 0,
        });

        this.resize(true);
        this.teradaResize(this.stage);

        this.coverLine = new PathLineBase("coverLine", this.coverLinePositions, {
            color: 0xcc9d58,
            dashOffset: 2,
        });

        // this.coverLine.setGui();
        this.scene.add(this.coverLine.mesh);

        gsap.to(this.coverLine.mesh.material, {
            dashOffset: 1,
            duration: 4,
            onComplete: () => {},
            ease: "Power2.easeInOut",
        });

        gsap.to(this, { coverLineRotationSpeed: 0.01, duration: 3, delay: 2 });

        let delay = 0.5;

        // this.cover.setGui();
        this.scene.add(this.cover.mesh);
        this.cover.maskCircleOut(this.cover.setRadiusByPixel(this.coverSize + 10), {
            duration: 1.5,
            delay: delay,
        });
        this.cover.maskCircleIn(this.cover.setRadiusByPixel(this.coverSize), {
            duration: 1.5,
            delay: delay + 1,
        });
        this.cover.update();

        // let coverLinePath: Array<number> = [];

        let length = this.coverLineLength;
        for (let i = 0; i < length; i++) {
            let r = ((length / 360) * 2 * Math.PI) / 180;
            let x = Math.cos(r * i) * (this.coverSize + i * r * 0.5) * 0.5;
            let y = Math.sin(r * i) * (this.coverSize + i * r * 0.5) * 0.5;
            let z = 0;

            this.coverLinePositions.push(x, y, z);
        }

        // this.container.rotation.z = Math.PI;

        if (this.operationMode) this.addEvent();
        this.updatePosition();

        this.setGui();
        this.update();

        this.resize();
        this.renderStart();

        this.isReady = true;

        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, 2000);
        });
    }

    async setup404() {
        this.isReady = true;
        gui_vec3(gui, this.camera.position, {
            folder: "camera",
            range: 10000,
            threshold: 0.1,
        }).close();

        let time: number = 0;

        this.update = () => {
            let sp = 0.05;

            time += sp;
            let id = 1;
            let power = 0;
            for (let i = 0; i < this.notFoundPath.points.length; i += 3) {
                power = time + (id % 2 == 0 ? 1 : -1); //(((time * j) / 3) % 3) * 1;
                power += Math.cos(i * time);
                this.notFoundPath.points[i + 0] =
                    this.notFoundPath.pointsOrigin[i + 0] + Math.cos(time + i / 1000) * 2;
                this.notFoundPath.points[i + 1] =
                    this.notFoundPath.pointsOrigin[i + 1] + Math.sin(time + i / 1000) * 2;

                if (i % 3 == 0) id++;
            }

            this.notFoundPath.updateLine(this.notFoundPath.points);
            this.notFoundPaths.forEach((pathLine, i) => {
                let id = 1;
                let power = 0;
                for (let j = 0; j < pathLine.points.length; j += 3) {
                    power = time + (id % 2 == 0 ? 1 : -1); //(((time * j) / 3) % 3) * 1;
                    power += Math.cos(j * time);
                    pathLine.points[j + 0] =
                        pathLine.pointsOrigin[j + 0] + Math.cos(time + j / 50 + i * 0.1) * 3;
                    pathLine.points[j + 1] =
                        pathLine.pointsOrigin[j + 1] + Math.sin(time + j / 50 + i * 0.1) * 3;

                    if (j % 3 == 0) id++;
                }
                pathLine.updateLine(pathLine.points);
            });
        };
        this.renderStart();
    }

    async load() {
        if (this.isLoaded) return;
        this.isLoaded = true;

        let loaders: Array<any> = [];
        let svgLoader = new SVGLoader();

        config.svgPaths.main.forEach(({ url, name, type, sceneId }, i) => {
            const loader = new Promise<void>((resolve) => {
                svgLoader.load(url, (data) => {
                    if (type == "mainScene") {
                        let divisions = 30;
                        if (sceneId > 0) {
                            divisions = config.connectionPositions[sceneId - 1].length * 5;
                        }
                        const points = GetSvgPathPoint(data, divisions);
                        const group = new Group();
                        const configuredPosition = config.scenePositions[i];

                        this.container.add(group);
                        this.sceneGroups[i] = group;

                        group.position.z = sceneId * config.sceneDist * config.sceneDirection;

                        if (configuredPosition) {
                            group.position.x = configuredPosition.x;
                            group.position.y = configuredPosition.y;
                            group.position.z = configuredPosition.z;
                        }

                        this.sceneSvgPathLines[i] = [];
                        points.forEach((point, j) => {
                            const pathLine = new LineBase(name, point, {});
                            this.sceneSvgPathLines[i][j] = pathLine;
                            group.add(pathLine.mesh);
                            if (j == 0 && i > 0) {
                                pathLine.mesh.visible = this.showGuidePath;
                            }
                        });
                    } else if (type == "skein") {
                        const points = GetSvgPathPoint(data, 30);
                        let positions: Array<Vector3> = [];
                        points.forEach((pointArr) => {
                            const v3 = new Vector3(pointArr[0], pointArr[1], pointArr[2]);
                            positions.push(v3);
                        });
                        this.skeinLine = new SkeinLine(positions);
                        this.container.add(this.skeinLine.group);
                        // this.sceneGroups[0].add(this.skeinLine.group);
                    }

                    resolve();
                });
            });
            loaders.push(loader);
        });

        config.svgPaths.sub.forEach(({ url, name, syncDomId }, i) => {
            const loader = new Promise<void>((resolve) => {
                svgLoader.load(url, (data) => {
                    let divisions = 10;
                    const svgSize = data.xml.viewBox.animVal;
                    const points = GetSvgPathPoint(data, divisions);
                    const group = new Group();
                    const paths: Array<PathLineBase> = [];
                    let cvsContainer: HTMLElement = document.querySelector(
                        syncDomId
                    ) as HTMLElement;

                    if (!cvsContainer) {
                        cvsContainer = document.createElement("div");
                    }

                    const captureRender = new CaptureRender(
                        cvsContainer,
                        this.stage.width,
                        this.stage.height,
                        0,
                        0,
                        svgSize.width,
                        svgSize.height
                    );

                    captureRender.resize(this.stage, group, svgSize, cvsContainer);

                    this.drawSvgToSceneList.push({
                        isActive: false,
                        svgSize: svgSize,
                        group: group,
                        paths: paths,
                        cvsContainer: cvsContainer,
                        captureRender: captureRender,
                        progress: 0,
                        update: function (value) {
                            this.progress = value;
                            this.paths.forEach((path: PathLineBase) => {
                                path.update(this.progress);
                            });
                        },
                    });

                    // this.drawSvgToSceneList[i].update(0);

                    points.forEach((point, i) => {
                        const pathLine = new PathLineBase(name, point, {});
                        paths.push(pathLine);
                        group.add(pathLine.mesh);
                    });

                    let g = gui.addFolder(name);
                    g.add({ progress: 0 }, "progress", 0, 1).onChange((value) => {
                        paths.forEach((path: PathLineBase) => {
                            path.update(value);
                        });
                    });

                    this.subScene.add(group);

                    resolve();
                });
            });
            loaders.push(loader);
        });

        // Promise.all(loaders).then(() => {
        // console.load("asd");
        // });

        return Promise.all(loaders);
    }

    async load404() {
        if (this.isLoaded) return;
        this.isLoaded = true;

        let svgLoader = new SVGLoader();
        await new Promise<void>((resolve) => {
            svgLoader.load(config.svgPaths.notFound.url, (data) => {
                let divisions = 20;
                const points = GetSvgPathPoint(data, divisions);
                const pathLines = [];
                this.notFountPathGroup = new Group();

                this.scene.add(this.notFountPathGroup);

                let mainLinePositions: Array<Vector3>;
                let endP;

                points.forEach((point, i) => {
                    const pathLine = new PathLineBase("404", point, {});
                    pathLine.update(0);
                    pathLine.setGui();
                    pathLines.push(pathLine);
                    this.notFountPathGroup.add(pathLine.mesh);

                    if (i == 0) {
                        pathLine.mesh.visible = false;
                        mainLinePositions = pathLine.getPositions();
                    }

                    this.notFoundPaths[i] = pathLine;
                });
                resolve();
            });
        });

        this.addResizeList("notFound", () => {
            this.update404Path();
        });
        this.update404Path();
    }

    teradaResize(e) {
        if (this.cameraPath) {
            this.setCameraPath();
        }

        if (e.width < 720) {
            let ratio = 1 - e.width / 720;
            this.coverSize = Math.min(557, e.width - 24 * 2);
            this.addCameraZoom = (1 - e.width / 720) * this.cameraZoomOffset;
        } else {
            this.coverSize = 557; //
            this.addCameraZoom = 0;
        }

        let vFOV = (this.camera.fov * Math.PI) / 180;
        let addY = this.screenZ * Math.cos(vFOV) * 0.5;
        // this.container.position.y = addY;

        if (this.cover) {
            this.cover.resize(this.stage);

            if (this.isReady) {
                this.cover.maskCircleOut(this.cover.setRadiusByPixel(this.coverSize + 10));
                this.cover.maskCircleIn(this.cover.setRadiusByPixel(this.coverSize));
            }
        }

        this.container.position.z = -this.addCameraZoom;

        this.drawSvgToSceneList.forEach(({ group, svgSize, captureRender, cvsContainer }, i) => {
            captureRender.resize(this.stage, group, svgSize, cvsContainer);
        });

        // up to center
        this.container.position.y = this.stage.marginHeight * 0.5;
        this.scene.position.y = this.stage.marginHeight;
    }

    update() {
        this.drawSvgToSceneList.forEach(({ captureRender, group, isActive }) => {
            if (isActive) {
                captureRender.render(this.renderer, this.subScene, group);
            }
        });
        if (this.orbitCtrl) this.orbitCtrl.update();
        if (this.isReady) {
            this.progress.smooth += (this.progress.crt - this.progress.smooth) * 0.1;

            if (this.cameraPath) {
                this.cameraUpdate(this.progress.smooth, this.progress2.crt);
            }

            let p = this.progress.smooth; //Math.round(this.progress.smooth * 10000) / 10000;
            if (p > 1) p = 1;
            const cameraPathPosition = this.cameraPath.curves;
            const cameraStep = Math.round(p * (cameraPathPosition.length - 1));

            // scene checking
            let currentSceneId = 0;
            let currentSceneP = 0;
            let isLineAnim = true;
            let isEnded = false;

            currentSceneP = cameraStep / (this.cameraPath.curves.length - 1);
            currentSceneId = Math.floor(currentSceneP);
            currentSceneP -= currentSceneId;

            currentSceneId = this.scenePositionId.findIndex((a) => {
                return a > cameraStep;
            });

            if (currentSceneId < 0) currentSceneId = this.scenes.length - 1;

            currentSceneP = cameraStep / this.scenePositionId[currentSceneId];

            if (currentSceneId == this.scenes.length - 1 && currentSceneP > 0.93) {
                isLineAnim = false;
                isEnded = true;
            }

            if (currentSceneP < 0.5 && currentSceneId == 0) {
                isLineAnim = false;
            }

            if (currentSceneId > 0) {
                currentSceneP =
                    (cameraStep - this.scenePositionId[currentSceneId - 1]) /
                    (this.scenePositionId[currentSceneId] -
                        this.scenePositionId[currentSceneId - 1]);
            }

            let scene = this.scenes[currentSceneId];
            if (scene) {
                scene.update(currentSceneP, this.mouse);
                if (isLineAnim) scene.updatePathAnimation();
            }

            if (currentSceneId > 0) {
                let prevScene = this.scenes[currentSceneId - 1];
                prevScene.update(1, this.mouse);
                if (isLineAnim) prevScene.updatePathAnimation();
            }

            if (currentSceneId < this.scenes.length - 1) {
                let nextScene = this.scenes[currentSceneId + 1];
                nextScene.update(0, this.mouse);
            }

            if (currentSceneId == this.scenes.length - 1) {
                if (window.innerWidth < 768) {
                    this.camAcc.y = -currentSceneP * window.innerHeight;
                } else {
                    this.camAcc.y = -currentSceneP * window.innerHeight * 0.5;
                }
            } else {
                this.camAcc.y = 0;
            }

            if (this.operationMode) {
                this.statusText = "";
                this.statusText += `progress : ${changeDecimal(this.progress.smooth, 100)}<br/>`;
                this.statusText += `camera p : ${this.camera.position.x}, ${this.camera.position.y} , ${this.camera.position.z}<br/>`;
                this.statusText += `camera r : ${this.camera.rotation.x}, ${this.camera.rotation.y} , ${this.camera.rotation.z}<br/>`;

                this.statusText += `cameraStep : ${cameraStep} / ${this.cameraPath.curves.length}<br/>`;
                this.statusText += `crt scene : ${currentSceneId} / ${this.scenes.length - 1}<br/>`;
                this.statusText += `crt scene p : ${changeDecimal(currentSceneP, 100)}<br/>`;
                this.statusText += `progress : ${changeDecimal(this.progress.smooth, 100)}<br/>`;

                this.statusEl.innerHTML = this.statusText;
            }

            this.progress.old = this.progress.smooth;

            this.cameraHelper.update();

            this.updateCoverLine();

            // animate sub svg path

            if (isEnded) {
                let sp = 0.04;
                this.subSceneTime += sp;
                this.drawSvgToSceneList.forEach((linePahs, i) => {
                    if (linePahs.isActive) {
                        let id = 1;
                        let power = 0;

                        linePahs.paths.forEach((pathLine: PathLineBase, k) => {
                            if (k == 0) return;

                            for (let j = 0; j < pathLine.points.length; j += 3) {
                                power = this.subSceneTime + (id % 2 == 0 ? 1 : -1); //(((this.subSceneTime * j) / 3) % 3) * 1;
                                power += Math.cos(j * this.subSceneTime);
                                pathLine.points[j + 0] =
                                    pathLine.pointsOrigin[j + 0] +
                                    Math.cos(this.subSceneTime + j / 50 + i * 0.1) * 1.1;
                                pathLine.points[j + 1] =
                                    pathLine.pointsOrigin[j + 1] +
                                    Math.sin(this.subSceneTime + j / 50 + i * 0.1) * 1.1;

                                if (j % 3 == 0) id++;
                            }
                            pathLine.updateLine(pathLine.points);
                        });
                    }
                });
            }
        }
    }

    private updateCoverLine() {
        let count = 0;
        let positions = this.coverLine.points;
        let cycle = 1;
        let rad = Math.PI / 180;
        let rot = this.coverLineRotation + Math.PI / 2;
        let radius = this.coverSize * 0.5;
        let movePower = Math.min(1, window.innerWidth / 720);

        for (let i = 0, n = positions.length; i < n; i += 3) {
            let r = (n / this.coverLineLength) * cycle * rad;

            let mx = 100 * (this.mouse._cx / window.innerWidth) * 0.5;
            let my = 100 * (this.mouse._cy / window.innerHeight) * 0.5;
            let center = (n / (i + 0.01)) * 1;

            let x = Math.cos(r * count + rot) * radius;
            let y = Math.sin(r * count + rot) * radius;

            /*
                animation 2
            */
            let cx = center * 0;
            let cy = center * 2;
            let _x = Math.cos(r * count - rot) * radius;
            let _y = Math.sin(r * count - rot) * radius;
            positions[i + 0] = x + cx;
            positions[i + 1] = y + cy;

            let cid = (count / n) * 4 * 4;
            let crad = cid * Math.PI;
            positions[i + 0] = x - Math.cos(crad) * 10 * movePower + ((mx * count) / n) * 2;
            positions[i + 1] = y - Math.sin(crad) * 10 * movePower + ((my * count) / n) * 2;
            positions[i + 0] += cx;
            positions[i + 1] += cy;
            count++;
        }
        this.coverLineRotation -= this.coverLineRotationSpeed;
        this.coverLine.updateLine(positions);
    }

    private cameraUpdate(progress = this.progress.crt, progress2 = this.progress2.crt) {
        if (this.cameraPath) {
            const cameraPathPosition = this.cameraPath.curves;
            const cameraPathLength = cameraPathPosition.length - 1;
            const p = progress * cameraPathLength;
            const step = Math.floor(p);

            const currentP = cameraPathPosition[step].clone();

            currentP.x += this.camAcc.x;
            currentP.y += this.camAcc.y * 1.2;
            currentP.y -= progress2 * 1000;

            let speed = 0.2;
            if (progress == 0) {
                speed = 1;
            }
            this.camera.position.x += (currentP.x - this.camera.position.x) * speed;
            this.camera.position.y += (currentP.y - this.camera.position.y) * speed;
            this.camera.position.z += (currentP.z - this.camera.position.z) * speed;
            // this.camera.position.copy(currentP);
        }
    }

    public updateLine(progress = 0, progress2 = 0) {
        this.progress.crt = progress;
        this.progress2.crt = progress2;
    }

    private update404Path() {
        let scale = 1;
        let y = 100;
        if (this.stage.width < 768) {
            scale = this.stage.width / 768;
            y = (1 - scale) * 250;
            scale *= 1.1;
        }
        this.notFountPathGroup.scale.set(scale, scale, scale);
        this.notFountPathGroup.position.y = y;

        let mainLinePositions: Array<Vector3> = this.notFoundPaths[0].getPositions();
        let length = mainLinePositions.length - 1;
        let startV3 = mainLinePositions[0];
        let endV3 = mainLinePositions[length];
        let sw = this.stage.width;
        let sh = this.stage.height;
        let p1 = new Vector3(
            startV3.x - sw * (0.5 + (1 - scale)),
            startV3.y + 50 + Math.random() * 200 - 100,
            0
        );
        let p1_ = new Vector3(
            startV3.x - sw * (0.4 + (1 - scale)),
            startV3.y + 20 + Math.random() * 150 - 25,
            0
        );
        let p2_ = new Vector3(endV3.x, endV3.y - Math.random() * 100 - 100, 10);
        let p2 = new Vector3(
            endV3.x + (this.stage.width * 0.2 + p2_.x),
            endV3.y - (p2_.y + Math.random() * sh),
            0
        );
        let n = 20; //Math.floor(mainLinePositions.length * 0.2);

        let connectPoint1 = [];
        let connectPoint2 = [];
        for (let i = 0, n = 3; i < n; i++) {
            let ratio = 1 - i / (n - 1);
            if (i == 0) {
                connectPoint2.push(new Vector3().lerpVectors(endV3, p2_, 1));
            } else {
                connectPoint2.push(new Vector3().lerpVectors(p2, p2_, ratio / 2));
            }

            if (i < 5) {
                connectPoint1.push(new Vector3().lerpVectors(p1, p1_, (1 - ratio) * 2));
            } else {
                connectPoint1.push(new Vector3().lerpVectors(startV3, p1_, -(ratio - 0.5) / 0.5));
            }
        }

        let feedNum = 30;
        for (let i = 0, n = feedNum; i < n; i++) {
            connectPoint1.push(mainLinePositions[i + 1]);
            connectPoint2.unshift(mainLinePositions[mainLinePositions.length - 1 - i]);
        }

        mainLinePositions = mainLinePositions.slice(
            feedNum,
            mainLinePositions.length - 1 - feedNum
        );

        connectPoint1 = makeCurvePoint(10, connectPoint1).points;
        connectPoint2 = makeCurvePoint(10, connectPoint2).points;

        if (0) {
            let pointMeshs: Array<Object3D> = [];
            this.notFountPathGroup.children.forEach((obj: Object3D) => {
                if (obj.name == "point") {
                    pointMeshs.push(obj);
                }
            });
            this.notFountPathGroup.remove(...pointMeshs);

            pointToPosition(connectPoint2).forEach((v3) => {
                let mesh = makeSimpleBox(v3, 1, 1);
                mesh.name = "point";
                this.notFountPathGroup.add(mesh);
            });
        }

        let mainPoints = positionToPoint(mainLinePositions);
        mainPoints = connectPoint1.concat(mainPoints);
        mainPoints = mainPoints.concat(connectPoint2);
        mainPoints = makeCurvePoint(5000, pointToPosition(mainPoints)).points;

        if (!this.notFoundPath) {
            this.notFoundPath = new PathLineBase("404Main", mainPoints);
            this.notFoundPath.update(1);
            this.notFountPathGroup.add(this.notFoundPath.mesh);
        } else {
            this.notFoundPath.updateLine(mainPoints);
            this.notFoundPath.pointsOrigin = this.notFoundPath.points.concat([]);
        }
        // this.notFoundPath.setGui();
    }

    public showNotFoundLine(delay: number = 0) {
        let progress = { main: 0, line1: 0, line2: 0 };
        gsap.to(progress, {
            main: 1,
            duration: 4,
            ease: "Power4.easeInOut",
            delay: delay,
            onUpdate: () => {
                this.notFoundPath.update(progress.main);
            },
        });
        gsap.to(progress, {
            line1: 1,
            duration: 2,
            delay: delay + 1,
            ease: "Power4.easeInOut",
            onUpdate: () => {
                this.notFoundPaths[2].update(progress.line1);
            },
        });
        gsap.to(progress, {
            line2: 1,
            duration: 2,
            delay: delay + 1.5,
            ease: "Power4.easeInOut",
            onUpdate: () => {
                this.notFoundPaths[3].update(progress.line2);
            },
        });
    }

    public updateSvg(progress: number, id: number) {
        if (this.drawSvgToSceneList.length > 0) {
            this.drawSvgToSceneList[id].update(progress);
        }
    }
    public updateSvgStatus(flag: boolean, id: number) {
        if (this.drawSvgToSceneList.length > 0) {
            this.drawSvgToSceneList[id].isActive = flag;
        }
    }

    updatePosition() {
        this.setConnectionLine();
        this.setCameraPath();
        this.setScenePath();
        this.setDragPointBox();
    }

    addEvent() {
        /*
            DragControls
        */
        this.dragCtrl = new DragControls(
            this.dragObjects,
            this.editCamera,
            this.renderer.domElement
        );
        this.dragCtrl.enabled = false;
        this.dragCtrl.addEventListener("drag", (e) => {
            if (e.object._pathLine) {
                let box = e.object;
                let id = box._id;
                let positions = pointToPosition(box._pathLine.points);
                positions.forEach((v3, i) => {
                    if (id == i) {
                        v3.copy(e.object.position);
                    }
                });

                e.object._pathLine.updateLine(positionToPoint(positions));
            } else if (e.object._object) {
                e.object._object.position.copy(e.object.position.clone());
            } else if (e.object._type.indexOf("cameraPath") > -1) {
                let groupId = e.object._groupId;
                let positions = this.cameraPathPositions[groupId];
                positions[e.object._id].copy(e.object.position.clone());
            }

            this.setConnectionLine();
            this.setScenePath();
            this.setCameraPath();
        });

        this.dragCtrl.addEventListener("dragend", (e) => {
            this.setConnectionLine();
            this.setScenePath();
            this.setCameraPath();
            this.setDragPointBox();
        });

        this.dragCtrl.addEventListener("dragstart", (e) => {
            if (keydownStatus.Shift && keydownStatus.Meta && this.isEditMode) {
                if (e.object._pathLine) {
                    let positions = pointToPosition(e.object._pathLine.points);
                    let crt = e.object._id;
                    let next = e.object._id + 1;

                    if (next < positions.length) {
                        let dist = new Vector3().addVectors(positions[crt], positions[next]);
                        let newP = dist.clone().divideScalar(2);

                        positions.splice(next, 0, newP);
                        e.object._pathLine.updateLine(positionToPoint(positions));
                    }
                } else {
                    if (e.object._type.indexOf("cameraPath") > -1) {
                        let groupId = e.object._groupId;
                        let crt = e.object._id;
                        let next = e.object._id + 1;
                        let positions = this.cameraPathPositions[groupId];

                        if (next < positions.length) {
                            let dist = new Vector3().addVectors(positions[crt], positions[next]);
                            let newP = dist.clone().divideScalar(2);

                            positions.splice(next, 0, newP);
                            // e.object._pathLine.updateLine(positionToPoint(positions));
                        }
                    }
                }
                this.setConnectionLine();
                this.setScenePath();
                this.setDragPointBox();
            }

            if (keydownStatus.Shift && keydownStatus.Alt && this.isEditMode) {
                if (e.object._pathLine) {
                    let positions = pointToPosition(e.object._pathLine.points);
                    let id = e.object._id;
                    if (id > 0 && id < positions.length - 1) {
                        positions.splice(id, 1);
                        e.object._pathLine.updateLine(positionToPoint(positions));
                        // this.setDragPointBox();
                    }
                } else {
                    if (e.object._type.indexOf("cameraPath") > -1) {
                        let groupId = e.object._groupId;
                        let id = e.object._id;
                        let next = e.object._id + 1;
                        let positions = this.cameraPathPositions[groupId];

                        if (id > 0 && id < positions.length - 1) {
                            positions = positions.splice(id, 1);
                            // e.object._pathLine.updateLine(positionToPoint(positions));
                            // this.setDragPointBox();
                        }

                        // if (next < positions.length) {
                        //     let dist = new Vector3().addVectors(positions[crt], positions[next]);
                        //     let newP = dist.clone().divideScalar(2);

                        //     positions.splice(next, 0, newP);
                        //     // e.object._pathLine.updateLine(positionToPoint(positions));
                        // }
                    }
                }

                this.setConnectionLine();
                this.setScenePath();
                this.setDragPointBox();
            }
        });

        let keydownStatus = {
            Shift: false,
            Meta: false,
            Alt: false,
        };

        let dragedObject = null;

        window.addEventListener("keydown", (e: KeyboardEvent) => {
            if (e.key === "Shift" && this.isEditMode) {
                this.dragCtrl.activate();
                this.dragCtrl.enabled = true;
                if (this.orbitCtrl) this.orbitCtrl.enabled = false;
                // enableSelection = e.keyCode === 16 ? true : false;
            }
            if (e.key == "Shift" || e.key == "Meta" || e.key == "Alt") {
                keydownStatus[e.key] = true;
                if (keydownStatus.Shift && keydownStatus.Meta && this.isEditMode) {
                    // console.log("create Point");
                }
            }

            if (e.key == "e") {
                this.editModeToggle(true);
            }
            if (e.key == "Escape") {
                this.editModeToggle(false);
            }

            if (e.key == "g") {
                if (this.statusEl.style.display == "block") {
                    this.statusEl.style.display = "none";
                    gui.domElement.style.display = "none";
                } else {
                    this.statusEl.style.display = "block";
                    gui.domElement.style.display = "block";
                }
            }
        });

        window.addEventListener("keyup", (e: KeyboardEvent) => {
            this.dragCtrl.enabled = false;
            this.dragCtrl.deactivate();
            if (this.orbitCtrl) this.orbitCtrl.enabled = true;
            for (let key in keydownStatus) {
                keydownStatus[key] = false;
            }
        });
    }

    setConnectionLine() {
        for (let i = 0; i < this.sceneGroups.length - 1; i++) {
            let id = i + 1;
            let sceneGroup = this.sceneGroups[id];

            const crtSvgPath = this.sceneSvgPathLines[i][0];
            const nextSvgPath = this.sceneSvgPathLines[i + 1][0];

            const crtSvgGroupP = crtSvgPath.mesh.parent.position;
            const nextSvgGroupP = nextSvgPath.mesh.parent.position;

            if (this.connectionPaths[i]) {
                let pathLine = this.connectionPaths[i];

                const pathLinePositions: Array<Vector3> = pathLine.getPositions();
                const crtSvgPositions: Array<Vector3> = crtSvgPath.getPositions();
                const nextSvgPositions: Array<Vector3> = nextSvgPath.getPositions().reverse();

                let tgP = crtSvgPositions[0].clone();

                if (i == 0) {
                    tgP = this.skeinLine.getEndPosition();
                }

                // set point to current group position
                tgP.x += crtSvgGroupP.x;
                tgP.y += crtSvgGroupP.y;
                tgP.z += crtSvgGroupP.z;
                pathLinePositions[0].copy(tgP);

                // set point to next group position
                tgP = nextSvgPositions[0].clone();
                tgP.x += nextSvgGroupP.x;
                tgP.y += nextSvgGroupP.y;
                tgP.z += nextSvgGroupP.z;
                pathLinePositions[pathLinePositions.length - 1].copy(tgP);

                pathLine.updateLine(positionToPoint(pathLinePositions));
            } else {
                let points: Array<number> = config.connectionPositions[i];
                let curve = 3000 | 0;

                if (!points) points = [];

                let crtSvgPositions: Array<Vector3> = crtSvgPath.getPositions();
                let crtVec3 = crtSvgPositions[0];

                let nextSvgPositions: Array<Vector3> = nextSvgPath.getPositions();
                let nextVec3 = nextSvgPositions[nextSvgPositions.length - 1];

                points.unshift(
                    crtVec3.x + crtSvgGroupP.x,
                    crtVec3.y + crtSvgGroupP.y,
                    crtVec3.z + crtSvgGroupP.z
                );
                points.push(
                    nextVec3.x + nextSvgGroupP.x,
                    nextVec3.y + nextSvgGroupP.y,
                    nextVec3.z + nextSvgGroupP.z
                );

                if (i == 0) {
                    let tgP = this.skeinLine.getEndPosition();
                    points[0] = tgP.x;
                    points[1] = tgP.y;
                    points[2] = tgP.z;
                }

                let line = new PathLineBase("connect" + i, points, {
                    color: config.color.connectionGuide,
                    dashArray: 0.02,
                    lineWidth: 1,
                    curve: curve,
                });

                line.mesh.visible = this.showGuidePath;
                this.connectionPaths[i] = line;
                this.container.add(line.mesh);
            }
        }
    }

    setScenePath() {
        /*
            scene path
        */
        let points: Array<number> = [];
        this.connectionPaths.forEach((pathLine, i) => {
            points = points.concat(pathLine.getCurvesToPoints());
            let connectionPoint = pathLine.getCurvesToPoints();

            let sceneSvg = this.sceneSvgPathLines[i + 1][0];
            let sceneSvgPosition = sceneSvg.getPositions();
            let sceneSvgGroup = sceneSvg.mesh.parent;

            sceneSvgPosition.forEach((v3) => {
                v3.x += sceneSvgGroup.position.x;
                v3.y += sceneSvgGroup.position.y;
                v3.z += sceneSvgGroup.position.z;
            });

            sceneSvgPosition.reverse();

            let svgPoints: any = positionToPoint(sceneSvgPosition);
            points = points.concat(svgPoints);
            connectionPoint = connectionPoint.concat(svgPoints);

            if (this.scenePathLines[i]) {
                this.scenePathLines[i].updateLine(connectionPoint);
            } else {
                let line: PathLineBase = new PathLineBase("scenePath" + i, connectionPoint, {
                    // color: i % 2 == 0 ? 0x00ff00 : 0x0000ff,
                    lineWidth: config.lineWidth,
                });
                // line.setGui();
                line.update(0);
                this.container.add(line.mesh);
                this.scenePathLines[i] = line;

                let scene;
                let sceneSvgParts = this.sceneSvgPathLines[i + 1];

                if (i == 0) {
                    scene = new Scene1(i, sceneSvgParts, line);
                    scene.skein = this.skeinLine;
                    scene.skeinGroup = this.sceneGroups[0];
                    // scene.group = this.sceneGroups[1];
                } else {
                    scene = new SceneBase(i, sceneSvgParts, line);
                }

                scene.name = config.sceneName[i];
                scene.group = this.sceneGroups[i + 1];
                scene.connectPath = this.connectionPaths[i];
                scene.cameraPath = this.cameraPaths[i];
                scene.cameraPathPosition = this.cameraPathPositions[i];
                scene.setGui();
                this.scenes[i] = scene;
            }
        });
    }

    setCameraPath() {
        // 1. set camera position each group

        let points: Array<Number> = [];
        this.sceneGroups.forEach((group, i) => {
            let v3 = group.position.clone();

            if (i < this.sceneGroups.length - 1) {
                let crtGroup = group;
                let nextGroup = this.sceneGroups[i + 1];

                let p1 = crtGroup.position.clone(); // fixed
                let p2 = crtGroup.position.clone(); // moveable
                let p3 = nextGroup.position.clone(); // fixed
                p1.z += this.screenZ;
                p3.z += this.screenZ;

                let line;

                // if (!this.cameraPathPositions[i]) this.cameraPathPositions[i] = [];

                if (!this.cameraPathPositions[i]) {
                    this.cameraPathPositions[i] = [];

                    let positions: Array<Vector3> = [];
                    let configuredPosition = config.cameraPathPositions[i];

                    if (configuredPosition && configuredPosition.length > 0) {
                        positions = pointToPosition(configuredPosition);
                        // positions.unshift(p2);
                        positions.unshift(p1);
                        positions.push(p3);

                        // positions.push(p3);
                    } else {
                        positions.push(p1);
                        positions.push(p2);
                        positions.push(p3);
                        // positions.push(p3);
                    }

                    this.cameraPathPositions[i] = positions;

                    // line = new PathLineBase("cameraPath" + i, positionToPoint(positions), {
                    //     color: config.color.cameraGuide,
                    //     dashArray: 0.01,
                    //     lineWidth: 1,
                    //     curve: 1,
                    // });

                    // this.cameraPaths[i] = line;
                    // this.guideGroup.add(line.mesh);
                    // points = points.concat(line.points);
                } else {
                    // let line = this.cameraPaths[i];
                    // let linePositions = line.getPositions();

                    let positions = this.cameraPathPositions[i];
                    positions[0].copy(p1);
                    // positions[positions.length - 1].copy(p3);

                    // linePositions[0].copy(p1);
                    // linePositions[1].copy(p2);
                    // linePositions[linePositions.length - 1].copy(p3);
                    // linePositions[linePositions.length - 2] = p2;
                    // linePositions[linePositions.length - 1] = p3;
                    // line.updateLine(positionToPoint(linePositions));
                }

                let positions = this.cameraPathPositions[i];
                // let point = this.cameraPaths[i].getCurvesToPoints();

                positions.forEach((v3) => {
                    // v3.z += 1000;
                    // v3.y += this.stage.marginHeight;
                });

                let _points = positionToPoint(positions);

                if (this.cameraPathTubes[i]) {
                    let cube: Mesh = this.cameraPathTubes[i];
                    cube.geometry.dispose();
                    this.container.remove(cube);
                }

                let material = new MeshBasicMaterial({
                    side: BackSide,
                    color: i % 2 == 0 ? 0xffff00 : 0x00ffff,
                    wireframe: true,
                    transparent: true,
                    opacity: 0.1,
                });
                let curveData = makeCurvePoint(500, positions);
                let curve = curveData.curve;
                let tube = new Mesh(this.makeTubeGeometry(curve), material);
                // tube.visible = this.showTubes;
                // this.container.add(tube);
                // this.cameraPathTubes[i] = tube;

                if (i > 0) {
                    _points = _points.slice(3);
                }
                points = points.concat(_points);
            }
        });

        if (!this.cameraPath) {
            // @ts-ignore
            this.cameraPath = new PathLineBase("cameraPath", points, {
                color: config.color.cameraGuide,
                dashArray: 0.01,
                lineWidth: 1,
                curve: config.cameraPathCurve,
            });
            // this.cameraPath.computeFrenetFrames();
            this.cameraPath.setGui();
            this.cameraPath.mesh.visible = this.showGuidePath;
            this.container.add(this.cameraPath.mesh);

            let material = new MeshBasicMaterial({
                side: BackSide,
                color: config.color.cameraGuide,
                wireframe: true,
                transparent: true,
                opacity: 0.1,
            });

            this.cameraPathTube = new Mesh(this.makeTubeGeometry(), material);
            this.cameraPathTube.visible = this.showGuidePath;
            this.container.add(this.cameraPathTube);
        } else {
            this.cameraPath.updateLine(points);
            this.setTube();
        }

        let positions = pointToPosition(points);

        config.cameraPathPositions.forEach((arr, i) => {
            if (arr.length == 0 && i > 0) {
                // let groupP = this.sceneGroups[i].position.clone();
                // groupP.z -= i * 1500;
                // arr = positionToPoint([groupP]);
            }

            if (i > 0) {
                let position = pointToPosition(arr)[0];
                positions.forEach((v3, j) => {
                    let dist = v3.distanceTo(position);
                    if (dist == 0) {
                        let ratio = j / (positions.length - 1);
                        let id = Math.floor(ratio * 0.95 * config.cameraPathCurve);

                        this.scenePositionId[i - 1] = id;

                        if (i == config.cameraPathPositions.length - 1) {
                            this.scenePositionId[i] = config.cameraPathCurve;
                        }

                        if (i == 0) {
                            this.scenePositionId[i] = 740;
                        }

                        if (i == 1) {
                            this.scenePositionId[i] = 2800;
                        }
                    }
                });

                this.scenePositionId[0] = 776;
                this.scenePositionId[1] = 2712;
                this.scenePositionId[2] = 4186;
                this.scenePositionId[3] = 5072;
                this.scenePositionId[8] = 9350;
                this.scenePositionId[9] = 10794;
            }
        });

        this.scenePositionId.forEach((id, i) => {
            if (i == 0) {
                this.scenePositionRatio[i] = id / (this.cameraPath.curves.length - 1);
            } else {
                this.scenePositionRatio[i] =
                    (id - this.scenePositionRatio[i - 1]) / (this.cameraPath.curves.length - 1);
            }

            if (i == this.scenePositionId.length - 1) {
                this.scenePositionRatio[i] = 1;
            }
        });
    }

    setDragPointBox() {
        if (!this.boxGroup) {
            this.boxGroup = new Group();
            this.container.add(this.boxGroup);
        }

        this.dragObjects.forEach((mesh: Mesh) => {
            (mesh as any)._pathLine = null;
            mesh.geometry.dispose();
            (mesh.material as MeshBasicMaterial).dispose();
        });

        this.dragObjects = [];
        this.boxGroup.clear();

        // scene connection line
        this.connectionPaths.forEach((pathLine, i) => {
            let positions = pointToPosition(pathLine.points);
            positions.forEach((v3, j) => {
                let mesh: any = makeSimpleBox(v3, 10, 10, 10, config.color.connectionGuide);
                mesh._id = j;
                mesh._type = "connect_" + j;
                mesh._pathLine = pathLine;
                mesh.visible = this.showGuidePath;

                if (j == 0 || j == positions.length - 1) {
                    // mesh.material.color = new Color(0x000000);
                }

                this.dragObjects.push(mesh);
                this.boxGroup.add(mesh);
            });
        });

        // camera line
        if (1) {
            let cameraPathPosition = pointToPosition(this.cameraPath.points);
            // cameraPathPosition.forEach((v3, i) => {
            //     let box: any = makeSimpleBox(v3, 10, 10, 10, config.color.cameraGuide);
            //     box._id = i;
            //     box._type = "cameraPath";
            //     box._pathLine = this.cameraPath;
            //     box.visible = this.showGuidePath;
            //     this.dragObjects.push(box);
            //     this.boxGroup.add(box);
            // });

            this.cameraPaths.forEach((pathLine) => {
                let positions = pointToPosition(pathLine.points);
                positions.forEach((v3, j) => {
                    let mesh: any = makeSimpleBox(v3, 10, 10, 10, config.color.connectionGuide);
                    mesh._id = j;
                    mesh._type = "cameraPath_" + j;
                    mesh._pathLine = pathLine;
                    mesh.visible = this.showGuidePath;

                    if (j == 0 || j == positions.length - 1) {
                        // mesh.material.color = new Color(0x000000);
                    }

                    this.dragObjects.push(mesh);
                    this.boxGroup.add(mesh);
                });
            });

            this.cameraPathPositions.forEach((positions, i) => {
                positions.forEach((v3, j) => {
                    if (j < positions.length - 1) {
                        let mesh: any = makeSimpleBox(v3, 20, 20, 20, config.color.cameraGuide);

                        mesh._groupId = i;
                        mesh._id = j;
                        mesh._type = "cameraPath_" + j;
                        // mesh._pathLine = pathLine;
                        mesh.visible = this.showGuidePath;
                        this.dragObjects.push(mesh);
                        this.boxGroup.add(mesh);
                    }
                });

                // console.log(`🚀 ~ position`, position);
            });
        }

        // scene group
        this.sceneGroups.forEach((group, i) => {
            let box: any = makeSimpleBox(group.position, 100, 100, 1, 0x00ff00);
            box.material.transparent = true;
            box.material.opacity = 0.5;
            box._id = i;
            box._type = "group";
            box._object = group;
            box.visible = this.showGuidePath;
            this.dragObjects.push(box);
            this.boxGroup.add(box);
        });

        if (this.operationMode) this.dragCtrl.setObjects(this.boxGroup.children);
    }

    setTube() {
        this.cameraPathTube.geometry.dispose();
        this.cameraPathTube.geometry = this.makeTubeGeometry();
    }

    getPositionRatio() {
        return this.scenePositionRatio;
    }

    makeTubeGeometry(curve = this.cameraPath.curve) {
        let tubeOption = this.cameraPathTubeOption;
        let geo = new TubeGeometry(
            curve,
            tubeOption.tubularSegments,
            tubeOption.tubuRadius,
            tubeOption.radiusSegments,
            false
        );
        return geo;
    }

    setGui() {
        gui_vec3(gui, this.camera.position, {
            folder: "camera",
            range: 10000,
            threshold: 0.1,
        }).close();

        gui.add(this, "currentDrawScene", ["main", "sub"]);
        gui_vec3(gui, this.subCamera.position, {
            folder: "subCamera",
            range: 10000,
            threshold: 0.1,
        }).close();

        gui.add({ progress: this.progress.crt }, "progress", 0, 1, 0.01).onChange((value) => {
            this.updateLine(value);
            this.cameraUpdate(value);
        });

        gui.add({ cameraHelper: this.cameraHelper.visible }, "cameraHelper").onChange((value) => {
            this.cameraHelper.visible = value;
        });

        gui.add(this, "showGuidePath").onChange((value) => {
            this.guideViewToggle(value);
        });

        gui.add(this, "isEditMode").onChange((value) => {
            this.editModeToggle(value);
        });

        gui.add(
            {
                connectLineReposition: () => {
                    this.updatePosition();
                },
            },
            "connectLineReposition"
        );

        gui.add(this.cameraPathTubeOption, "tubularSegments").onChange(() => {
            this.setTube();
        });
        gui.add(this.cameraPathTubeOption, "tubuRadius").onChange(() => {
            this.setTube();
        });
        gui.add(this.cameraPathTubeOption, "radiusSegments").onChange(() => {
            this.setTube();
        });

        gui.add(this.cameraPathTubeOption, "scale", -10, 10).onChange(() => {
            this.setTube();
        });

        gui.add(this.cameraPathTubeOption, "offset", -30, 30).onChange(() => {
            this.setTube();
            this.cameraUpdate();
        });

        gui.add(this.cameraPathTubeOption, "stablization", -10, 1000).onChange(() => {
            this.setTube();
            this.cameraUpdate();
        });

        gui.add(this.cameraPathTubeOption, "lookAhead");
        gui.add(this.cameraPathTube, "visible");
        gui.add(this, "showTubes").onChange((value) => {
            this.cameraPathTubes.forEach((mesh) => {
                mesh.visible = value;
            });
        });

        const g = gui.addFolder("groups");
        this.sceneGroups.forEach((group, i) => {
            // let _g = g.addFolder("group" + i);
            //@ts-ignore
            let _g = gui_vec3(g, group.position, {
                folder: "group" + i,
                range: 10000,
                thresold: 1,
            });
            _g.close();
        });
    }

    editModeToggle(value: boolean) {
        this.showGuidePath = value;
        if (value) {
            this.isEditMode = true;
            let v3 = this.camera.position.clone();
            v3.z = Math.min(0, v3.z);

            // this.editCamera = this.camera.clone();
            this.editCamera.position.copy(this.camera.position);

            /*
            	Controls
			*/
            this.orbitCtrl = new OrbitControls(this.editCamera, this.renderer.domElement);
            this.orbitCtrl.enableDamping = true;

            this.orbitCtrl.target.copy(v3);
            this.orbitCtrl.target.z -= 500;
            this.guideViewToggle(true);
        } else {
            if (this.orbitCtrl) {
                this.orbitCtrl.dispose();
            }

            this.isEditMode = false;
            this.orbitCtrl.enabled = false;
            this.guideViewToggle(false);
        }
    }

    guideViewToggle(value: boolean) {
        this.cameraPath.mesh.visible = value;
        this.connectionPaths.forEach((pathLine) => {
            pathLine.mesh.visible = value;
        });

        this.boxGroup.children.forEach((mesh) => {
            mesh.visible = value;
        });

        this.sceneGroups.forEach((group) => {
            group.children.forEach((mesh, i) => {
                if (i == 0) {
                    mesh.visible = value;
                }
            });
        });

        this.cameraPathTube.visible = value;

        this.cameraPathTubes.forEach((mesh) => {
            mesh.visible = value;
        });
    }
}
