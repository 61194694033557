import gsap from "gsap";
import { MeshLine, MeshLineMaterial } from "meshline";
import { Color, DoubleSide, Mesh } from "three";
import config from "../config";
import { gui } from "../GUI";
import { makeCurvePoint, pointToPosition, positionToPoint } from "../utils";

export default class PathLineBase {
	meshLine;
	mesh;
	name = "";

	gsap;
	config = {
		transparent: true,
		lineWidth: 1,
		color: new Color(config.color.main),
		dashArray: 2, // always has to be the double of the line
		dashOffset: 1, // start the dash at zero
		dashRatio: 0.5,

		curve: 0,
	};

	progress = 0;
	curveDivision = 5000;
	isCurve = false;

	curve;
	curves = [];
	points = []; // number x,y,z...
	pointsOrigin;

	tangents;
	normals;
	binormals;

	constructor(name = "", points = [0, 0, 0], option = {}) {
		this.name = name;
		this.points = points;
		this.config = Object.assign(this.config, option);

		this.meshLine = new MeshLine();
		const lineMaterial = new MeshLineMaterial({
			transparent: this.config.transparent,
			lineWidth: this.config.lineWidth,
			color: this.config.color,
			dashArray: this.config.dashArray,
			dashOffset: this.config.dashOffset,
			dashRatio: this.config.dashRatio,
			side: DoubleSide,
		});

		this.mesh = new Mesh(this.updateGeometry(), lineMaterial);
	}

	updateLine(points) {
		if (points) this.points = points;
		if (this.mesh.geometry) this.mesh.geometry.dispose();
		this.mesh.geometry = this.updateGeometry();
	}

	updateGeometry() {
		let points = [];
		if (this.config.curve > 0) {
			const data = makeCurvePoint(this.config.curve, pointToPosition(this.points));
			this.curve = data.curve;
			points = data.points;
			this.curves = pointToPosition(points);
		} else {
			points = this.points;
		}

		if (!this.pointsOrigin) this.pointsOrigin = points.concat([]);

		this.meshLine.setGeometry(points);
		return this.meshLine.geometry;
	}

	computeFrenetFrames() {
		let data = this.curve.computeFrenetFrames(this.points.length / 3);
		this.binormals = data.binormals;
		this.tangents = data.tangents;
		this.normals = data.normals;
	}

	getCurves() {
		return this.curves;
	}

	getCurvesToPoints() {
		return positionToPoint(this.curves);
	}

	getPositions() {
		return pointToPosition(this.points);
	}

	setGui() {
		let g = gui.addFolder(this.name).close();
		g.add(this, "progress", 0, 1, 0.001).onChange(() => {
			this.update(this.progress);
		});
		g.add(this.mesh.material, "lineWidth", 0, 5);
		g.add(this.mesh.material, "dashArray", 0, 2);
		g.add(this.mesh.material, "dashOffset", 0, 2, 0.001);
		g.add(this.mesh.material, "dashRatio", 0, 1);
		g.add(this.mesh, "visible");
		g.add(this.config, "curve", 0, 5000).onChange(() => {
			this.updateGeometry();
		});

		g.add(
			{
				ポイント位置Copy: () => {
					const handler = (e) => {
						document.removeEventListener("copy", handler);
						e.clipboardData.setData("text/plain", JSON.stringify(this.points));
						e.preventDefault();
					};
					document.addEventListener("copy", handler);
					document.execCommand("copy");
				},
			},
			"ポイント位置Copy"
		);
	}

	update(progress) {
		this.progress = progress;
		this.drawLine();
	}

	// updateProperty(prop){
	//     if(this.mesh.material[prop]) {
	//     }
	// }

	drawLine() {
		this.mesh.material.dashOffset = 2 - this.progress;
	}

	drawTo(to, duration = 2, delay = 0) {
		if (this.gsap) this.gsap.kill();
		this.gsap = gsap.to(this, {
			progress: to,
			delay: delay,
			duration: duration,
			ease: "Power4.easeInOut",
			onUpdate: () => {
				this.drawLine();
			},
		});
	}
}
