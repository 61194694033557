import { Color, OrthographicCamera } from "three";
import { Fog } from "three";
import { PerspectiveCamera, Scene, WebGLRenderer } from "three";
import config from "./config";
import UA from "./lib/ua";

export default class BaseWebGL {
	renderList: any;
	resizeList: any;
	containerEl: HTMLElement;
	stage: any;
	config: any;
	mouse: any;
	isRender: boolean = false;
	renderId: number;

	subScene: Scene = new Scene();
	scene: Scene;

	currentDrawScene: string = "main";

	camera: PerspectiveCamera;
	subCamera: OrthographicCamera;
	editCamera: PerspectiveCamera;
	renderer: WebGLRenderer;

	screenZ: number;

	isEditMode: boolean = false;

	dummyEl: HTMLElement;

	constructor(containerEl, option = {}) {
		this.renderList = {};
		this.resizeList = {};

		this.containerEl = containerEl;

		this.stage = {
			width: 1920,
			height: 1080,
			aspect: 4 / 3,
			marginHeight: 1080,
		};

		this.mouse = { x: 0, y: 0, cx: 0, cy: 0, _cx: 0, _cy: 0, rx: 0, ry: 0 };

		this.defineSize();

		this.config = {
			camera_fov: 45,
			camera_near: 1,
			camera_far: 35000,

			render_antialias: true,
			render_alpha: false,
			render_pixelRatio: window.devicePixelRatio,

			render_clearColor: config.color.base,
			render_clearColorAlpha: 1,
			render_autoClearColor: true,
		};

		this.config = Object.assign(this.config, option);

		this.isRender = false;

		/*
			Scene, Camera, Renderer
		*/
		this.scene = new Scene();
		this.scene.fog = new Fog(new Color(config.color.main), 1, 10000);

		this.camera = new PerspectiveCamera(
			this.config.camera_fov,
			this.stage.aspect,
			this.config.camera_near,
			this.config.camera_far
		);

		this.subCamera = new OrthographicCamera(
			this.stage.width / -2,
			this.stage.width / 2,
			this.stage.height / 2,
			this.stage.height / -2,
			0,
			2000
		);
		this.subCamera.position.z = 100;

		this.editCamera = new PerspectiveCamera(
			this.config.camera_fov,
			this.stage.aspect,
			this.config.camera_near,
			this.config.camera_far
		);

		this.renderer = new WebGLRenderer({
			antialias: this.config.render_antialias,
			// alpha: this.config.render_alpha,
			// autoClearColor: this.config.render_autoClearColor,
			stencil: false,
			depth: false,
		});

		this.screenZ = this.computeZ(0);

		this.renderer.setClearColor(this.config.render_clearColor);
		this.renderer.setPixelRatio(this.config.render_pixelRatio);
		this.renderer.setSize(this.stage.width, this.stage.height);
		this.render = this.render.bind(this);

		if (this.containerEl) this.containerEl.prepend(this.renderer.domElement);

		/*
			Event , Stats, Helpers
		*/
		this.resize = this.resize.bind(this);
		window.addEventListener("resize", () => {
			this.resize();
		});

		if (UA.isPC) {
			window.addEventListener("mousemove", (e) => {
				let _x = this.mouse.x;
				let _y = this.mouse.y;

				this.mouse.x = e.clientX;
				this.mouse.y = e.clientY;
				let widthHalf = window.innerWidth * 0.5;
				let heightHalf = window.innerHeight * 0.5;
				this.mouse.cx = this.mouse.x - widthHalf;
				this.mouse.cy = -(this.mouse.y - heightHalf);
				this.mouse.rx = this.mouse.x / widthHalf;
				this.mouse.ry = 1 - this.mouse.y / heightHalf;

				let dx = _x - this.mouse.x;
				let dy = _y - this.mouse.y;
				this.mouse.acc = Math.min(100, Math.sqrt(dx * dx + dy * dy)) / 100;
			});
		}
	}

	defineSize() {
		this.stage.width = this.containerEl.clientWidth;
		// this.stage.height = document.documentElement.clientHeight; //this.containerEl.clientHeight;
		this.stage.height = this.containerEl.clientHeight;
		this.stage.aspect = this.stage.width / this.stage.height;
		this.stage.marginHeight = this.containerEl.clientHeight - document.documentElement.clientHeight;
	}

	resize(force: boolean = false) {
		if (!force) {
			if (UA.isIOS && this.containerEl.clientWidth == this.stage.width) return;
		}

		this.defineSize();
		this.screenZ = this.computeZ(0);
		// if (this.scene) this.scene.position.y = this.stage.marginHeight;

		this.camera.aspect = this.stage.aspect;
		this.camera.updateProjectionMatrix();

		this.subCamera.left = this.stage.width / -2;
		this.subCamera.right = this.stage.width / 2;
		this.subCamera.top = this.stage.height / 2;
		this.subCamera.bottom = this.stage.height / -2;
		this.subCamera.updateProjectionMatrix();

		this.editCamera.aspect = this.stage.aspect;
		this.editCamera.updateProjectionMatrix();

		this.renderer.setSize(this.stage.width, this.stage.height);
		this.updateResizeList(this.stage);
	}

	computeZ(targetZ, targetHeight1 = this.stage.height, targetHeight2 = this.stage.height) {
		let vFOV = (this.camera.fov * Math.PI) / 180;
		let vHeightPartial = 2 * Math.tan(vFOV / 2);
		var p1 = targetHeight1 * this.stage.height;
		var p2 = targetZ * vHeightPartial;
		var p3 = targetHeight2 * vHeightPartial;
		var p4 = targetHeight2 * p2;
		var p5 = p1 + p4;
		var z = p5 / p3;
		return z;
	}

	/*
        render list, resize list
    */

	renderStart() {
		if (!this.isRender) {
			requestAnimationFrame(this.render);
		}
		this.isRender = true;
	}

	renderStop() {
		this.isRender = false;
		cancelAnimationFrame(this.renderId);
	}

	addResizeList(key, target) {
		this.resizeList[key] = target;
	}

	removeRenderList(key) {
		delete this.renderList[key];
	}

	removeResizeList(key) {
		delete this.resizeList[key];
	}

	updateResizeList(...arg: any) {
		for (let key in this.resizeList) {
			this.resizeList[key](...arg);
		}
	}

	render() {
		this.renderId = requestAnimationFrame(this.render);

		this.update();

		if (this.currentDrawScene == "main") {
			this.renderer.render(this.scene, this.camera);
		} else {
			this.renderer.render(this.subScene, this.camera);
		}

		this.mouse._cx += (this.mouse.cx - this.mouse._cx) * 0.1;
		this.mouse._cy += (this.mouse.cy - this.mouse._cy) * 0.1;

		if (this.isEditMode) {
			this.renderer.render(this.scene, this.editCamera);
		} else {
		}
	}

	update() {}
}
