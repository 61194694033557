import { gsap } from "gsap";
import { Color, Mesh, PlaneBufferGeometry, ShaderMaterial, Vector2 } from "three";
import { gui } from "./GUI";

export default class CircleMesh {
	constructor(name = "", option = {}) {
		option = Object.assign(
			{
				width: 1920,
				height: 1080,
				transparent: true,
				opacity: 1,
				baseColor: 0xffffff,
				colors: [],
				maskRadiusInPixel: 518,
				maskRadiusIn: 0,
				maskRadiusOut: 1,
				maskTextureRadius: 1,
				segment: 1,
			},
			option
		);

		this.option = option;
		this.name = name;
		this.width = option.width;
		this.height = option.height;

		this.colors = [];
		this.colorsPosition = [];
		// this.option.colors.forEach((data, i) => {
		//     this.colors[i] = new Color(data.color);
		//     this.colorsPosition[i] = data.p;
		// });

		this.maskRadius = {
			in: option.maskRadiusIn,
			out: option.maskRadiusOut,
		};

		this.gsaps = {
			maskRadiusIn: null,
			maskRadiusOut: null,
		};

		this.maskCirclePosition = new Vector2(0.5, 0.5);
		this.tPosition = new Vector2(0.5, 0.5);

		const material = new ShaderMaterial({
			transparent: option.transparent,
			uniforms: {
				resolution: {
					value: new Vector2(option.width, option.height),
				},
				uAspect: { value: option.height / option.width },
				uBaseColor: { value: new Color(option.baseColor) },
				uOpacity: { value: option.opacity },

				// uColors: { type: "v3v", value: this.colors },
				// uColorsPosition: { type: "fv1", value: this.colorsPosition },

				uCenter: { value: this.maskCirclePosition },
				tPosition: { value: this.tPosition },
				maskRadius: { value: 1 },
				maskRadiusIn: { value: this.maskRadius.in },
				maskRadiusOut: { value: this.maskRadius.out },

				uTime: { value: 0 },
				uLightScale: { value: 0 },
			},
			vertexShader: `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
				}
			`,
			fragmentShader: `
				varying vec2 vUv;

				uniform vec2 resolution;
				uniform vec2 uCenter;
				uniform vec3 uBaseColor;
				uniform float uAspect;
				uniform float uOpacity;
				uniform float maskRadius;
				uniform float maskRadiusIn;
				uniform float maskRadiusOut;
				uniform float uTime;
				uniform float uLightScale;
				// uniform vec3 uColors[2];
				// uniform float uColorsPosition[2];

				float PI = 3.141592653589793;

				float circle(in vec2 _st, in vec2 _center, in float _aspect, in float _radius){
					vec2 dist = (_st - _center);
      				dist.y = dist.y * _aspect;
					float circleRadius = 1.0;
					float l = length(dist);
					if(_radius < l){
						circleRadius = 1. - smoothstep(0.0,0.001,l-_radius);
					}
					if(_radius == 0.0){
						circleRadius = .0;
					}

					return circleRadius;
				}

				void main(){

					/*
						circle radius mask
					*/
					float aspect = resolution.y / resolution.x;
					vec2 uv = vUv;
					vec2 maskUv = vUv;

					vec2 dist1 = vec2(0.5) - vec2(0.0);
					dist1.y *= aspect;
					float fullRadiusMin = sqrt(dist1.x * dist1.x + dist1.y * dist1.y);

					vec2 dist2 = uCenter - vec2(0.0);
					if(dist2.y < 0.5)dist2.y = 0.5 + 0.5 - dist2.y;
					if(dist2.x < 0.5)dist2.x = 0.5 + 0.5 - dist2.x;
					dist2.y *= aspect;
					float fullRadius = sqrt(dist2.x * dist2.x + dist2.y * dist2.y);
					float _maskRadius = circle(maskUv,uCenter,aspect,maskRadiusOut * fullRadius);

					float maskRadiusInValue = 0.0;
					maskRadiusInValue = maskRadiusIn * maskRadius * fullRadiusMin;
					if(maskRadiusIn > 1.0){
						maskRadiusInValue += (maskRadiusIn - 1.0) * (fullRadius - maskRadiusInValue);
					}else{
						// maskRadiusInValue = maskRadius + (maskRadiusIn - 1.0) * (1.0 - maskRadius);
						//maskRadiusIn * maskRadius * fullRadius;
					}

					float _maskRadiusIn = 1. - circle(maskUv,uCenter,aspect,maskRadiusInValue);

					/*
						light
					*/
					// vec2 st = maskUv * 2. -1.;
					vec2 c = uCenter;
					uv.y *= aspect;
					c.y *= aspect;
					float d = distance(uv,c);

					float circleColor = circle(maskUv,uCenter,aspect,d);
					vec3 destColor = vec3(circleColor);//mix(uColors[0],uColors[1],d / fullRadius * 1.5);

					if(d > fullRadius * _maskRadius) {
						destColor = uBaseColor;
					}

					float opacity = uOpacity * _maskRadiusIn;
					gl_FragColor = vec4(destColor,opacity);
				}
			`,
		});

		const geometry = new PlaneBufferGeometry(
			option.width,
			option.height,
			this.option.segment,
			this.option.segment
		);
		this.mesh = new Mesh(geometry, material);
		this.mesh.material.depthWrite = false;
		this.mesh.geometry.dispose();
	}

	load() {
		// return new Promise((resolve) => {
		// });
	}

	update() {
		this.mesh.material.uniforms.uTime.value += 0.01;
	}

	setGui() {
		const g = gui.addFolder(this.name);
		g.add(this.maskRadius, "in", 0, 2, 0.01).onChange(this.maskRadiusUpdate.bind(this));
		g.add(this.maskRadius, "out", 0, 1, 0.01).onChange(this.maskRadiusUpdate.bind(this));

		g.add(this.maskCirclePosition, "x", 0, 1, 0.01).onChange(this.maskRadiusUpdate.bind(this));
		g.add(this.maskCirclePosition, "y", 0, 1, 0.01).onChange(this.maskRadiusUpdate.bind(this));
		g.add(this.mesh.material.uniforms.uLightScale, "value", -1, 1, 0.01);
	}

	setRadiusPixel(size = 400) {
		const diagonal = Math.sqrt(this.width * this.width + this.height * this.height);
		this.mesh.material.uniforms.maskRadius.value = size / diagonal;
	}

	setRadiusByPixel(size = 400) {
		const diagonal = Math.sqrt(this.width * this.width + this.height * this.height);
		return size / diagonal;
	}

	visible(bool) {
		this.mesh.visible = bool;
	}

	opacity(opacity, option = {}) {
		this.mesh.material.uniforms.uOpacity = opacity;
	}

	scale(scale, option = {}) {}

	maskCircleIn(radius = 1, option = {}) {
		if (this.gsaps.maskRadiusIn) this.gsaps.maskRadiusIn.kill();
		option = Object.assign(
			{
				in: radius,
				duration: 0,
				delay: 0,
				ease: "Power4.easeInOut",
				onUpdate: this.maskRadiusUpdate.bind(this),
			},
			option
		);
		this.gsaps.maskRadiusIn = gsap.to(this.maskRadius, option);
	}

	maskCircleOut(radius = 1, option = {}) {
		if (this.gsaps.maskRadiusOut) this.gsaps.maskRadiusOut.kill();
		option = Object.assign(
			{
				out: radius,
				duration: 0,
				delay: 0,
				ease: "Power4.easeInOut",
				onUpdate: this.maskRadiusUpdate.bind(this),
				onComplete: () => {
					this.maskRadiusUpdate();
				},
			},
			option
		);
		this.gsaps.maskRadiusOut = gsap.to(this.maskRadius, option);
	}

	maskRadiusUpdate() {
		this.mesh.material.uniforms.maskRadiusIn.value = this.maskRadius.in;
		this.mesh.material.uniforms.maskRadiusOut.value = this.maskRadius.out;
		this.mesh.material.uniforms.uCenter.value.x = this.maskCirclePosition.x;
		this.mesh.material.uniforms.uCenter.value.y = this.maskCirclePosition.y;
	}

	show() {
		this.mesh.visible = true;
	}

	hide() {
		this.mesh.visible = false;
	}

	resize({ width, height, marginHeight }) {
		this.width = width;
		this.height = height + marginHeight;

		this.mesh.geometry = new PlaneBufferGeometry(
			this.width,
			this.height,
			this.option.segment,
			this.option.segment
		);
		const uniforms = this.mesh.material.uniforms;
		uniforms.resolution.value.x = this.width;
		uniforms.resolution.value.y = this.height;
		if (width < height) {
			// uniforms.aspect.value = this.height / this.width;
		} else {
		}
		uniforms.uAspect.value = this.height / this.width;
		this.mesh.geometry.dispose();
	}
}
