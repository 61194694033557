import { BoxGeometry } from "three";
import { Mesh } from "three";
import { MeshBasicMaterial } from "three";
import { Vector3 } from "three";
import { CatmullRomCurve3 } from "three";

const dummyData = {
	news: [
		[
			"2022/10/26",
			"コーポレートサイト リニューアルのお知らせ -- ",
			"https://note.com/teradaknit/n/n02f238cd7166",
			"_blank",
		],
	],
	"facilities[main]": [
		["16G -- ", "MACH2X-18L", "38"],
		["12G", "MACH2XS-12S", "4"],
		["12G", "MACH2XS-15L", "2"],
		["12G", "SWG173-X12", "12"],
		["12G", "SWG203-X12", "3"],
		["7G", "MACH2S-14", "8"],
		["5G", "MACH2S-08", "3"],
		["3G", "SES-204CW05", "2"],
	],
	"facilities[small]": [
		["21G", "SFG20-21", "1"],
		["15G", "SWG-061N2", "2"],
		["15G", "SWG-091N", "1"],
		["10G", "SWG-091N", "1"],
	],
	caution: [
		["※小物専用機は手袋や靴下などしか編めないため、21Gでセーターのようなニットは編めません。"],
	],
};

function makeCurvePoint(num = 500, positions = []) {
	let curve = new CatmullRomCurve3(positions);
	let points = [];

	curve.getPoints(num).forEach(({ x, y, z }) => {
		points.push(x, y, z);
	});

	return { curve, points };
}

function pointToPosition(points) {
	const positions = [];
	for (let i = 0; i < points.length; i += 3) {
		positions.push(new Vector3(points[i + 0], points[i + 1], points[i + 2]));
	}

	return positions;
}

function positionToPoint(positions) {
	let points = [];

	positions.forEach(({ x, y, z }) => {
		points.push(x, y, z);
	});

	return points;
}

function makeSimpleBox(vec3, w = 10, h = 10, d = 10, color = 0xff0000) {
	let geo = new BoxGeometry(w, h, d);
	let mat = new MeshBasicMaterial({ color: color });
	let mesh = new Mesh(geo, mat);
	mesh.position.copy(vec3);
	return mesh;
}

function changeDecimal(value, decimal = 1000) {
	return Math.round(value * decimal) / decimal;
}

function clipBoardCopy(data) {
	const handler = (e) => {
		document.removeEventListener("copy", handler);
		e.clipboardData.setData("text/plain", data);
		e.preventDefault();
	};
	document.addEventListener("copy", handler);
	document.execCommand("copy");
}

async function loadCSV() {
	try {
		const target = `/assets/data.csv`; //file
		const res = await fetch(target, {
			method: "get",
			headers: {
				"content-type": "text/csv; charset=UTF-8",
				//'Authorization': //in case you need authorisation
			},
		});

		if (res.status === 200) {
			let data = await res.text();
			data = data.replaceAll("\r", "");
			data = data.replaceAll(",,,", "");

			const table = data.split(/\n/).slice(1);

			const filtered = table.filter((value) => {
				if (value != ",,,") return value;
			});

			// create empty array
			const csvData = [];

			// // this will return each line as an individual String
			const lines = data.split("\n");

			// loop through the lines and return an array of individual
			// Strings within the line that are separated by a comma
			for (let i = 0; i < lines.length; i++) {
				let value = lines[i].split(",");
				if (value[0] == "") {
				} else {
					csvData.push(value);
				}
			}

			const group = [
				{ name: "news", id: null, list: [] },
				{ name: "facilities[main]", id: null, list: [] },
				{ name: "facilities[small]", id: null, list: [] },
				{ name: "caution", id: null, list: [] },
			];

			// define number of array
			csvData.forEach((dataArr, i) => {
				group.forEach((groupData) => {
					if (groupData.name == dataArr[0]) {
						groupData.id = i;
					}
				});
			});

			// data parser
			group.forEach((now, i) => {
				if (i > 0) {
					let before = group[i - 1];
					before.list = csvData.slice(before.id + 1, now.id);
				}
				if (i == group.length - 1) {
					let before = group[i - 1];
					now.list = csvData.slice(now.id + 1);
				}
			});

			const result = {};

			group.forEach((data) => {
				result[data.name] = data.list;
			});

			return result;
		} else {
			console.log(`Error code ${res.status}`);
			return dummyData;
		}
	} catch (err) {
		console.log(`err ${err}`);
		return dummyData;
	}
}

export {
	makeCurvePoint,
	pointToPosition,
	positionToPoint,
	makeSimpleBox,
	changeDecimal,
	clipBoardCopy,
	loadCSV,
};
