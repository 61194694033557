import { MeshLine, MeshLineMaterial } from "meshline";
import { Color, Group, Mesh, MeshBasicMaterial, Sphere, SphereGeometry, Vector3 } from "three";
import config from "./config";
import { gui } from "./GUI";

export default class SkeinLine {
	name = "skeinLine";
	mesh;
	sphere;
	group = new Group();
	lineMeshOffset = {
		transparent: true,
		depthTest: false,
		lineWidth: config.lineWidth,
		color: new Color("#cb9c58"),
		dashArray: 2, // always has to be the double of the line
		dashOffset: 1, // start the dash at zero
		dashRatio: 0.5, // visi
	};
	length = 7000;
	skew = 0;
	scale = 1;
	progress = 0;
	radius = 100;
	skeinPoints;
	constructor(skeinPoints) {
		this.skeinPoints = skeinPoints;
		// console.log(`🚀 ~ skeinPoints`, );
		// const points = [];
		// for (let i = 0; i < 100; i++) {
		//     let count = i / 5;
		//     let p = i / 1000;
		//     let x = Math.cos(count) * this.radius * p;
		//     let y = i / 10;
		//     let z = Math.sin(count) * this.radius * p;
		//     points.push(x, y, z);
		// }

		const meshLine = new MeshLine();
		meshLine.setGeometry(this.setPoint(skeinPoints, this.length));

		const lineGeo = meshLine.geometry;
		const lineMaterial = new MeshLineMaterial(this.lineMeshOffset);

		this.mesh = new Mesh(lineGeo, lineMaterial);
		this.group.add(this.mesh);

		this.setGui();
	}

	setPoint(skeinPoints, length = 100) {
		let p1 = new Vector3().copy(skeinPoints[0]);
		let p2 = new Vector3().copy(skeinPoints[1]);
		let p3 = new Vector3().copy(skeinPoints[2]);
		let p4 = new Vector3().copy(skeinPoints[3]);

		let radius1 = (p2.x - p1.x) * 0.5;
		let radius2 = (p4.x - p3.x) * 0.5;
		radius2 -= radius1;

		let height = p1.y - p3.y;
		height *= 1;

		const points = [];
		let half = length * 0.5;
		const PI = Math.PI / 180;
		for (let i = 0; i < length; i++) {
			let count = i / 20;
			let p = i / length;
			let hp = 1 - Math.abs((i / length - 0.5) / 0.5);
			let radius = radius1 + radius2 * hp;
			radius *= this.scale;
			let y = p1.y - height * hp;
			if (i < half) {
				y += Math.cos(count) * this.skew;
			} else {
				y += Math.cos(count) * -this.skew;
			}
			let startRad = Math.PI * 0.5 - 19 * PI;
			let x = Math.cos(count - startRad) * radius;
			let z = Math.sin(count - startRad) * radius;
			points.push(x, y, z);
		}
		return points;
	}

	getStartPosition() {
		let positions = this.mesh.geometry.positions;
		let position = positions.slice(0, 3);
		return new Vector3(position[0], position[1], position[2]);
	}
	getEndPosition() {
		let positions = this.mesh.geometry.positions;
		let position = positions.slice(positions.length - 3);
		return new Vector3(position[0], position[1], position[2]);
	}

	getPositions() {
		return this.mesh.geometry.positions;
	}

	setGui() {
		let g = gui.addFolder(this.name).close();
		g.add(this, "progress", 0, 1, 0.001);
		g.add(this.lineMeshOffset, "lineWidth", 0, 5).onChange((value) => {
			this.mesh.material.lineWidth = value;
		});
		g.add(this.lineMeshOffset, "dashArray", 0, 2).onChange((value) => {
			this.mesh.material.dashArray = value;
		});
		g.add(this.lineMeshOffset, "dashOffset", 0, 2, 0.001).onChange((value) => {
			this.mesh.material.dashOffset = value;
		});
		g.add(this.lineMeshOffset, "dashRatio", 0, 1).onChange((value) => {
			this.mesh.material.dashRatio = value;
		});

		g.add(this, "length", 100, 50000, 1).onChange((value) => {
			this.length = value;
			const meshLine = new MeshLine();
			meshLine.setGeometry(this.setPoint(this.skeinPoints, this.length));
			const lineGeo = meshLine.geometry;
			this.mesh.geometry.dispose();
			this.mesh.geometry = meshLine.geometry;
		});

		g.add(this, "skew", 0, 100).onChange((value) => {
			this.skew = value;
			const meshLine = new MeshLine();
			meshLine.setGeometry(this.setPoint(this.skeinPoints, this.length));
			const lineGeo = meshLine.geometry;
			this.mesh.geometry.dispose();
			this.mesh.geometry = meshLine.geometry;
		});

		g.add(this, "scale", 0.5, 10, 0.01).onChange((value) => {
			const meshLine = new MeshLine();
			meshLine.setGeometry(this.setPoint(this.skeinPoints, this.length));
			const lineGeo = meshLine.geometry;
			this.mesh.geometry.dispose();
			this.mesh.geometry = meshLine.geometry;
		});
	}

	update(progress) {
		this.mesh.material.dashOffset = progress;
	}

	// update(speed) {
	//     let attributePositions = this.mesh.geometry.attributes.position.array;
	//     let positions = this.mesh.geometry.positions;
	//     // console.log(
	//     //     `🚀 ~ attributePositions`,
	//     //     attributePositions.length,
	//     //     positions.length
	//     // );
	//     speed *= 0.1;
	//     for (let i = 0; i < 1000; i += 3) {
	//         // attributePositions[i + 0] += Math.cos(i / 10) * this.radius;
	//         // attributePositions[i + 1] += ;
	//         // attributePositions[i + 2] += 1;
	//         // positions[i + 0] += Math.cos(i / 10) * speed;
	//         // positions[i + 1] += speed;
	//         // positions[i + 2] += Math.sin(i / 10) * speed;
	//         // attributePositions[i + 0] = positions[i + 0];
	//         // attributePositions[i + 1] = positions[i + 1];
	//         // attributePositions[i + 2] = positions[i + 2];
	//     }
	//     // this.mesh.geometry.needsUpdate = true;
	//     // this.mesh.geometry.positions.needsUpdate = true;
	//     this.mesh.geometry.attributes.position.needsUpdate = true;

	//     let p = Math.floor((this.progress * (positions.length - 1)) / 3);
	//     let x = positions[p * 3 + 0];
	//     let y = positions[p * 3 + 1];
	//     let z = positions[p * 3 + 2];
	//     // this.sphere.position.set(x, y, z);
	//     return new Vector3(x, y, z);
	//     // console.log(`🚀 ~ p`, p);

	//     // this.mesh.geometry
	//     // this.mesh.geometry.positions
	// }
}
