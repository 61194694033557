import { Group } from "three";
import PathLineBase from "./lib/PathLineBase";
import SceneBase from "./SceneBase";
import SkeinLine from "./SkeinLine";

export default class Scene1 extends SceneBase {
	skein: SkeinLine;
	skeinGroup: Group;
	constructor(id = -1, sceneSvgParts: Array<PathLineBase>, scenePath: PathLineBase) {
		super(id, sceneSvgParts, scenePath);
	}

	in() {
		this.sceneSvgParts.forEach((pathLine, i) => {
			pathLine.drawTo(1, i * 0.1);
		});
	}

	animation() {
		// this.progress = progress;
		// this.scenePath.update(this.progress);
		this.skein.update(1 - this.progress * 0.5);
		this.skeinGroup.rotation.y = -this.progress * Math.PI * 5;
	}
}
