import TeradaStory from "./teradaStory/TeradaStory";
import { gui } from "./teradaStory/GUI";
let progress = 0;
let teradaStory;
let mouse = { x: 0, y: 0 };

async function setup() {
    teradaStory = new TeradaStory(document.getElementById("terada"), true);

    await teradaStory.load();
    await teradaStory.setup();

    document.body.append(gui.domElement);

    document.addEventListener("keydown", (e) => {
        if (e.key == "g") {
            if (gui.domElement.style.visibility == "hidden") {
                gui.domElement.style.visibility = "visible";
            } else {
                gui.domElement.style.visibility = "hidden";
            }
        }
    });

    addEvent();
}

function addEvent() {
    document.addEventListener(
        "mousemove",
        (e) => {
            mouse.x = e.clientX - window.innerWidth * 0.5;
            mouse.y = e.clientY - window.innerHeight * 0.5;
        },
        false
    );
    document.addEventListener("mousewheel", (e) => {
        if (!teradaStory.isEditMode) {
            progress += e.deltaY * 0.00005;
            progress = Math.min(1, Math.max(0, progress));
            teradaStory.updateLine(progress);
        }
    });
}

setup();
