import { Vector2 } from "three";

export default function GetSvgPathPoint(svgData: any, divisions = 30) {
	let svgSize = svgData.xml.viewBox.animVal;
	const paths = svgData.paths;

	const points: Array<any> = [];
	for (let i = 0; i < paths.length; i++) {
		const path = paths[i];
		const strokeColor = path.userData.style.stroke;

		for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
			const subPath = path.subPaths[j];
			const curve: any = [];
			subPath.getPoints(divisions).forEach((v2: Vector2, i) => {
				curve.push(v2.x - Number(svgSize.width * 0.5), v2.y * -1 + svgSize.height * 0.5, i * 0.01);
			});

			points.push(curve);
		}
	}
	return points;
}
