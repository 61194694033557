import { Group, Object3D, OrthographicCamera } from "three";

export default class CaptureRender {
	size: any = {
		width: 100,
		height: 100,
	};

	camera: OrthographicCamera;
	canvas: HTMLCanvasElement;
	ctx: CanvasRenderingContext2D;
	constructor(
		canvasContainer: HTMLElement,
		stageWidth,
		stageHeight,
		viewX,
		viewY,
		viewWidth,
		viewHeight
	) {
		this.canvas = document.createElement("canvas");
		this.ctx = this.canvas.getContext("2d") as CanvasRenderingContext2D;
		this.camera = new OrthographicCamera(
			stageWidth / -2,
			stageWidth / 2,
			stageHeight / 2,
			stageHeight / -2,
			0,
			2000
		);

		canvasContainer.append(this.canvas);

		this.canvas.width = viewWidth * window.devicePixelRatio;
		this.canvas.height = viewHeight * window.devicePixelRatio;
		this.canvas.style.width = "100%";
		this.canvas.style.height = "100%";

		this.camera.setViewOffset(stageWidth, stageHeight, viewX, viewY, viewWidth, viewHeight);
		// this.camera.setViewOffset(stageWidth, stageHeight, 0, 0, stageWidth, stageHeight);
		this.camera.position.z = 100;
	}
	resize(stage, target: Object3D, svgSize, cvsContainer: HTMLElement) {
		let sw = stage.width;
		let sh = stage.height;

		this.camera.left = sw / -2;
		this.camera.right = sw / 2;
		this.camera.top = sh / 2;
		this.camera.bottom = sh / -2;
		this.camera.updateProjectionMatrix();
		target.visible = false;

		// target.scale.set(0.5, 0.5, 0.5);

		// origin center to left top
		let x = target.position.x + stage.width * 0.5 - svgSize.width * 0.5;
		let y = target.position.y + stage.height * 0.5 - svgSize.height * 0.5;

		this.canvas.width = svgSize.width * window.devicePixelRatio;
		this.canvas.height = svgSize.height * window.devicePixelRatio;

		this.camera.setViewOffset(sw, sh, x, y, sw, sh);
	}
	render(renderer, scene, target: Object3D) {
		target.visible = true;
		renderer.render(scene, this.camera);
		this.ctx.drawImage(renderer.domElement, 0, 0);
		target.visible = false;
	}
}
