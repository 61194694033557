export default {
	sceneDirection: -1,
	sceneDist: 5000,
	sceneEditMode: false,

	camera_position_z: 1500,

	lineWidth: 1,

	color: {
		main: 0xcb9c58,
		base: 0xfcfaf4,
		cameraGuide: 0xff0000,
		connectionGuide: 0x0000ff,
	},

	connectionCurves: [3000, 3000, 3000, 3000, 3000, 3000],
	cameraPathCurve: 12000,
	connectionPathIndex: ["first", "first", "first", "last", "last", "first"],
	sceneName: [
		"fuji",
		"baby",
		"machine",
		"clothes1",
		"clothes2",
		"clothes3",
		"clothes4",
		"clothes5",
		"family",
		"knit",
		"empty",
	],
	connectionPositions: [
		[
			123.68500534504035, -1.6770726063552672, -434.2757439457583, -82.98553452756906,
			-136.82638521429456, -2441.4854678261686, 82.84224038685312, -197.7244003230773,
			-3510.9000186233466, -271.7162132092804, -200.86623684089173, -4485.926901339457,
			200.54012897329338, -211.03305963614798, -5003.4721277469735,
		], // fuji
		[
			1146.1859998465943, -35.42861072625112, -4989.490228526715, 757.0818326253382,
			179.22540585420597, -5770.556984734579, 2592.107107093593, 251.8073718756193,
			-6886.694455029443, 1648.7121029606228, 384.50657358370177, -9023.713588956383,
		], // baby
		[
			2467.6921575389797, -180.60767404031503, -9045.631795738014, 2136.1211913923867,
			-444.89735902183645, -8776.951777022985, 2171.233663540261, -1537.6989424443066,
			-9521.891837514626, 2000.298118608462, -2263.4804652963676, -10298.978356935017,
		], // machine

		[
			1027.7255664891563, -3353.127014423897, -10412.223322483629, 1658.975338469334,
			-3933.3776862688333, -10809.137480966452, 1785.5611035800785, -3938.31635957647,
			-11928.624988916325, 1488.5793370104866, -3319.973126468564, -15023.130497623086,
		], // clothes1
		[
			2616.971594437969, -3626.2402744977835, -15033.534102326485, 2538.3495509267627,
			-3546.497373513755, -15252.107286880886, 1621.6385835617227, -3517.5850857517084,
			-16284.835481175416, 2339.8857450753485, -3544.9114972810144, -16240.377407270298,
		], // clothes2
		[
			2392.745218043935, -4009.676769795864, -16297.805993720023, 2136.23777668949,
			-3791.040857709978, -17434.599854516266,
		], // clothes3
		[
			2436.3509757678908, -3425.65123926889, -17531.95083744384, 3003.026494645654,
			-3366.1087974114766, -18708.75206402754,
		], // clothes4
		[
			3061.4257995482203, -3929.1314323033407, -18777.057835906377, 2487.3301880233926,
			-3576.6824077837455, -19908.570447407932,
		], // clothes5
		[
			3009.5468522548676, -3795.262086529867, -19981.613853675288, 2607.441255750202,
			-3857.9267477188855, -21813.48162218559, 4261.226497003023, -2825.605246969303,
			-22198.95441291898, 4128.427041662138, -1928.362722417802, -23588.640582365693,
		], // family
		[
			6294.83475171891, -1732.1181008323842, -23661.69180388181, 5118.937536236734,
			-1278.1124351643834, -27159.515033364612,
		], // knit
		[
			6748.9692893593465, -1827.6734458697379, -27222.910479109192, 8256.16084741772,
			-2173.2580620046547, -28170.720996677108, 9822.225279840488, -1890.9594538938777,
			-28011.785006234553,
		], //empty
	],

	cameraPathPositions: [
		[], // fuji
		[
			70.6362252477204, 2.4955101830882995, -4142.169904847924, 1357.7853263589357,
			-129.4022693673532, -4019.3835402447053, 681.3008582778717, 80.26314264035628,
			-4780.241876736772, 1737.6110748822714, 98.7119294396123, -6224.426943987052,
		], // baby
		[
			2301.074441475846, -153.71613824878168, -8301.10315698712, 2003.5087564394757,
			-1224.912722813172, -8599.58124861989, 1689.1688200546105, -2445.4550189172824,
			-8972.685860383453,
		], // machine
		[1238.2989582390774, -4183.504752495407, -10682.63998428522], // clothes1
		[2432.597718018448, -3634.709827778889, -14927.643330005247], // clothes2
		[2405.2201370961407, -3733.973180503212, -16240.259703722202], // clothes3
		[2657.1214883237826, -3670.0721956614802, -17508.57458735772], // clothes4
		[2829.216162233507, -3686.042442596518, -18716.557737188923], // clothes5
		[
			2721.149981389846, -3657.9399493513206, -20122.230021560667, 4041.7039738572744,
			-2859.8375207565728, -21480.214776865003,
		], // family

		[
			5277.277052881053, -1549.3928260305215, -22768.044428711917, 6232.307489070733,
			-1675.8880234186759, -23869.980251609413, 5733.547603791636, -1219.512857416171,
			-25536.21828033672,
		], // knit
		[
			7011.9853434179295, -1967.4633458710357, -26117.546393469827, 8549.412188879403,
			-2304.907275171392, -26039.622790440237,
		], // last
	],

	scenePositions: [
		{ x: 0, y: 0, z: 0 }, // skein
		null, // fuji
		{ x: 2256.0786131233344, y: 93.09726251367726, z: -9052.0813750206 }, // baby
		{ x: 1681.3109661074423, y: -2818.7993505133554, z: -10403.765165715651 }, // machine
		{ x: 2413.634468270929, y: -3717.5078462726533, z: -15025.134161873571 }, // clothes1
		{ x: 2411.2649842589876, y: -3745.2250528181517, z: -16267.54382328389 }, // clothes2
		{ x: 2649.2600392162867, y: -3667.2309818808953, z: -17527.963147002803 }, // clothes3
		{ x: 2751.5284997510103, y: -3640.9944310490896, z: -18807.08670404067 }, // clothes4
		{ x: 2801.666776517289, y: -3634.450109457466, z: -20059.945153696062 }, // clothes5
		{ x: 5159.782344821462, y: -1586.9836184446426, z: -23666.277443071216 }, // family
		{ x: 6184.17842714509, y: -1553.1614001025957, z: -27245.01012140652 }, // knit
		{ x: 11210.624061513772, y: -2416.1618625385177, z: -27719.565017074558 },
	],

	svgPaths: {
		main: [
			{
				url: "/assets/teradastory/line1.svg",
				name: "scene1",
				type: "mainScene",
				sceneId: 0,
			},
			{
				url: "/assets/teradastory/line2.svg",
				name: "scene2",
				type: "mainScene",
				sceneId: 1,
			},
			{
				url: "/assets/teradastory/line3.svg",
				name: "scene3",
				type: "mainScene",
				sceneId: 2,
			},
			{
				url: "/assets/teradastory/line4.svg",
				name: "scene4",
				type: "mainScene",
				sceneId: 3,
			},
			{
				url: "/assets/teradastory/line5.svg",
				name: "scene5",
				type: "mainScene",
				sceneId: 4,
			},
			{
				url: "/assets/teradastory/line5-1.svg",
				name: "scene5_1",
				type: "mainScene",
				sceneId: 5,
			},
			{
				url: "/assets/teradastory/line5-2.svg",
				name: "scene5_2",
				type: "mainScene",
				sceneId: 6,
			},
			{
				url: "/assets/teradastory/line5-3.svg",
				name: "scene5_3",
				type: "mainScene",
				sceneId: 7,
			},
			{
				url: "/assets/teradastory/line5-4.svg",
				name: "scene5_4",
				type: "mainScene",
				sceneId: 8,
			},

			{
				url: "/assets/teradastory/line6.svg",
				name: "scene6",
				type: "mainScene",
				sceneId: 9,
			},

			{
				url: "/assets/teradastory/line7.svg",
				name: "scene7",
				type: "mainScene",
				sceneId: 10,
			},
			{
				url: "/assets/teradastory/empty.svg",
				name: "scene8",
				type: "mainScene",
				sceneId: 11,
			},

			{
				url: "/assets/teradastory/line1-point.svg",
				name: "skeinPoint",
				sceneId: 0,
				type: "skein",
			},
		],

		sub: [
			{
				url: "/assets/teradastory/computer.svg",
				name: "computer",
				syncDomId: "#svgComputer",
			},
			{
				url: "/assets/teradastory/sheep.svg",
				name: "sheep",
				syncDomId: "#svgSheep",
			},
		],

		notFound: {
			url: "/assets/teradastory/404.svg",
			name: "404",
		},
	},
};
